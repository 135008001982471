import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Deep Thoughts" link="https://mighty-lake-44841.herokuapp.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A blog website built with the MERNG stack. <br /> You can make posts and add replies and reactions.
    </ProjectCard>
    <ProjectCard title="Shop Shop" link="https://pacific-thicket-76980.herokuapp.com/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  An e-commerce platform built with the MERNG stack and Stripe API.
    </ProjectCard>
    <ProjectCard title="Code Review" link="https://fast-beach-15102.herokuapp.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A platform to connect you with experienced developers for work or help with projects.
    </ProjectCard>
    <ProjectCard title="Photo Port" link="https://gurkirat.me/photo-port" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A photography portfolio built with React.
    </ProjectCard>
    <ProjectCard title="COVacation" link="https://stevtm.github.io/COVacation/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Flight search application that provides up-to-date COVID statistics at the destination country.
    </ProjectCard>
    <ProjectCard title="Just Tech News" link="https://pacific-dawn-72541.herokuapp.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A hacker-news clone built with Node.js and Handlebars.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      