import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Hello! My name is Gurkirat Saini. I enjoy creating innovative web designs for every type of user. My interest in web development started when I was 8 years old. I saw a
family-friend create an amazing website using just the notepad on his computer. It was magic watching his ideas come to life and from then on I’ve been hooked. `}<br />{`
Presently, I have had the privilege of building and maintaining a vast variety of Enterprise Management solutions.`}</p>
    <p>{`Here are a few technologies I've been working with lately:`}<br /></p>
    <ul>
      <li>JavaScript</li>    <li>React</li>
      <li>Node.js</li>         <li>Firebase</li>
      <li>Stripe</li>          <li>GraphQL</li>
      <li>AWS</li>            <li>Azure</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      